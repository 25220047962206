<template>
<b-card>

    <validation-observer ref="simpleRules">
        <b-form>
            <!-- Section Dealer -->
            <h4>{{ $t('Dealer')}}</h4>
            <span class="font-weight-bold">{{$t('The fields with (*) are required')}}</span>
            <hr class="mb-2">
            <b-row>
                <!-- Field: Dealer -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Owner')" label-for="Dealer">

                        <vue-autosuggest v-model="searchQuery" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange" >
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                                <div v-show="suggestion.item.ownerName">
                                  <span class="text-muted">{{ $t('Belongs to: ') }}</span>
                                  <span class="font-weight-bold"> {{ suggestion.item.ownerName }} </span>
                                </div>
                            </template>
                        </vue-autosuggest>
                        <!--<b-form-input id="name" v-model="searchQuery" v-if="dealerData.id" disabled/>-->

                    </b-form-group>
                </b-col>

                <!-- Field: Language -->
                <b-col cols="12" md="4" style="list-style: none;">
                    <b-form-group :label="$t('Language')" label-for="language">

<!--                        <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>-->
<!--                            <template #button-content>-->
<!--                                <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />-->
<!--                                <span class="ml-50 text-body">{{ currentLocale.name }}</span>-->
<!--                            </template>-->
<!--                            <b-dropdown-item class="float-left" v-for="localeObj in locales" :key="localeObj.locale" @click="$i18n.locale = localeObj.locale">-->
<!--                                <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />-->
<!--                                <span class="ml-50">{{ localeObj.name }}</span>-->
<!--                            </b-dropdown-item>-->
<!--                        </b-nav-item-dropdown>-->

                      <validation-provider #default="{ errors }" name="profile" rules="required">
                        <v-select :state="errors.length > 0 ? false : null" v-model="dealerData.language"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="languageOptions"
                                  :reduce="val => val.value" :clearable="false" input-id="profile"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                </b-col>
            </b-row>

            <h4 class="mt-2">{{ $t('Informations')}}</h4>
            <hr class="mb-2">
            <b-row>
                <!-- Field: isCorporate -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Is Corporate')" label-for="is-corporate">
                        <v-select v-model="dealerData.isCorporate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="isCorporateOptions" :reduce="val => val.value" :clearable="false" input-id="is-corporate" />
                    </b-form-group>
                </b-col>

                
                <!-- Field: Plan -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Plan')" label-for="plan">
                        <validation-provider #default="{ errors }" name="plan" rules="">
                            <v-select :state="errors.length > 0 ? false:null" v-model="dealerData.planId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="planOpt" :reduce="val => val.value" :clearable="false" input-id="plan" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Name -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Name')+'*'" label-for="name">
                        <validation-provider #default="{ errors }" name="name" rules="required|min:3|max:50">
                            <b-form-input id="name" v-model="dealerData.name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Corporate Name -->
                <b-col cols="12" md="4" v-show="dealerData.isCorporate == true">
                    <b-form-group :label="$t('Corporate Name')" label-for="corporate-name">
                        <validation-provider #default="{ errors }" name="corporate-name" rules="max:50">
                            <b-form-input id="corporate-name" v-model="dealerData.corporateName" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Trade Name -->
                <b-col cols="12" md="4" v-show="dealerData.isCorporate == true">
                    <b-form-group :label="$t('Trade Name')" label-for="trade-name">
                        <validation-provider #default="{ errors }" name="trade-name" rules="max:50">
                            <b-form-input id="trade-name" v-model="dealerData.tradeName" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Document -->
                <b-col cols="12" md="4">
                    <b-form-group :label="dealerData.isCorporate == true ? 'CNPJ*' : 'CPF*'" label-for="document">
                        <validation-provider #default="{ errors }" name="document" rules="required|min:11|max:20|regex:^[0-9-.\/]*$">
                            <cleave id="document" v-model="dealerData.document" class="form-control" :raw="false" :options="dealerData.isCorporate == true ? options.cnpj : options.cpf" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: State Inscription -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('State Inscription')" label-for="state-inscription">
                        <validation-provider #default="{ errors }" name="state-inscription" rules="max:50">
                            <b-form-input id="state-inscription" v-model="dealerData.stateInscription" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field:  Timezone -->
                <b-col cols="12" md="4">
                    <b-form-group label="Timezone*" label-for="time">
                        <validation-provider #default="{ errors }" name="time" rules="required">
                            <b-form-input id="time" v-model="dealerData.timezone" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>

            <h4 class="mt-3"><h4>{{$t('Contacts')}}</h4></h4>
            <hr class="mb-2">
            <!-- Section Contacts -->
            <b-row>

                <!-- Field: Manager -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Manager')" label-for="manager">
                        <validation-provider #default="{ errors }" name="manager" rules="max:50">
                            <b-form-input id="manager" v-model="dealerData.manager" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Manager Email -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Manager Email')" label-for="manager-email">
                        <validation-provider #default="{ errors }" name="manager-email" rules="max:255|email">
                            <b-form-input id="manager-email" v-model="dealerData.managerEmail" type="email" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Manager Phone -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Manager Phone')" label-for="manager-phone">

                        <validation-provider #default="{ errors }" name="manager-phone" rules="max:20">

                            <cleave id="manager-phone" v-model="dealerData.managerPhone" class="form-control" :raw="false" :options="phoneMask" placeholder="31 9 9999 0000" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Tech Manager -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Tech Manager')" label-for="tech-manager">
                        <validation-provider #default="{ errors }" name="tech-manager" rules="max:50">
                            <b-form-input id="tech-manager" v-model="dealerData.techManager" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Tech Manager Email -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Tech Manager Email')" label-for="tech-email">
                        <validation-provider #default="{ errors }" name="tech-email" rules="max:250|email">
                            <b-form-input id="tech-email" v-model="dealerData.techEmail" type="email" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Tech Manager Phone -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Tech Manager Phone')" label-for="tech-phone">
                        <validation-provider #default="{ errors }" name="tech-phone" rules="max:20">
                            <cleave id="tech-phone" v-model="dealerData.techPhone" class="form-control" :raw="false" :options="phoneMask" placeholder="31 9 9999 0000" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Finance Manager -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Finance Manager')" label-for="finance-manager">
                        <validation-provider #default="{ errors }" name="finance" rules="max:50">
                            <b-form-input id="finance-manager" v-model="dealerData.financeManager" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Finance Manager Email -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Finance Manager Email')" label-for="finance-email">
                        <validation-provider #default="{ errors }" name="finance-email" rules="max:250|email">
                            <b-form-input id="finance-email" v-model="dealerData.financeEmail" type="email" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Finance Manager Phone -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Finance Manager Phone')" label-for="tech-phone">
                        <validation-provider #default="{ errors }" name="finance-phone" rules="max:20">
                            <cleave id="finance-phone" v-model="dealerData.financePhone" class="form-control" :raw="false" :options="phoneMask" placeholder="21 9 9999 0000" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: zipcode -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Zip Code')" label-for="zipcode">
                        <validation-provider #default="{ errors }" name="zipcode" rules="max:20">
                            <cleave id="zipcode" v-model="dealerData.zipCode" class="form-control" :raw="false" :options="zipMask" />

                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Country -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Country')" label-for="country">
                        <validation-provider #default="{ errors }" name="country" rules="max:30">
                            <b-form-input id="country" v-model="dealerData.country" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Neighborhood -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Neighborhood')" label-for="neighborhood">
                        <validation-provider #default="{ errors }" name="neighborhood" rules="max:50">
                            <b-form-input id="neighborhood" v-model="dealerData.neighborhood" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Address -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Address')" label-for="address">
                        <validation-provider #default="{ errors }" name="address" rules="max:200">
                            <b-form-input id="address" v-model="dealerData.address" placeholder="Rua Oito, 123 - Casa C" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!-- Field: Notes -->
                <b-col cols="12" md="12">
                    <b-form-group :label="$t('Notes')" label-for="notes">
                        <validation-provider #default="{ errors }" name="address" rules="max:255">
                            <b-form-textarea v-model="dealerData.note" id="notes" rows="5" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col v-if="!user.role.endsWith('VI')" cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm">
                        {{$t('Save')}}
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</b-card>
</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
} from 'bootstrap-vue'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import dealerStoreModule from './dealerStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    confirmed,
    url,
    between,
    alpha,
    integer,
    password,
    min,
    digits,
    alphaDash,
    length,
    max
} from '@validations'

import {
    VueAutosuggest
} from 'vue-autosuggest'

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,

        Cleave,
        vSelect,
        VueAutosuggest

    },
    data() {
        return {
            myId: router.currentRoute.params.id,
            required,
            email,
            confirmed,
            url,
            between,
            alpha,
            integer,
            password,
            min,
            digits,
            alphaDash,
            length,
            max,
            teste: ``,

            languageOptions:[{label: 'Português', value: 'pt'}, {label: 'English', value: 'en'}],

            options: {

                cpf: {
                    delimiters: ['.', '.', '-'],
                    blocks: [3, 3, 3, 2],
                    uppercase: true,
                },
                cnpj: {
                    delimiters: ['.', '.', '/', '-'],
                    blocks: [2, 3, 3, 4, 2],
                    uppercase: true,

                },

            },

            phoneMask: {
                //prefix: '55',
                //delimiters: [' (', ') ', ' ', ' '],
                blocks: [2, 1, 4, 4],
                //uppercase: true,
                //phone: true,
                //phoneRegionCode: 'BR',
                numericOnly: true
            },

            zipMask: {
                //prefix: '55',
                delimiters: ['.', '-'],
                blocks: [2, 3, 3],
                numericOnly: true
                //uppercase: true,
                //phone: true,
                //phoneRegionCode: 'BR',
            },

            isCorporateOptions: [{
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                },

            ],

            blokedOptions: [{
                    label: 'Yes',
                    value: true
                },
                {
                    label: 'No',
                    value: false
                },

            ],

            datasuggest: [],
            filteredOptions: [],
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search for your customer ...",
            },
            limit: 3,
            selected: null,
        }
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    //this.dealerData.language = this.$i18n.locale
                    if (this.dealerData.id === "") {
                        store.dispatch('app-dealer/addDealer', {
                                dealerData: this.dealerData
                            })
                            .then(response => {
                                this.msgDialog(this.$t('Successful registration'), 'success')
                                router.push({
                                    name: 'list-dealer'
                                })
                                //this.clearForm();

                            })
                            .catch(error => {
                                this.msgDialog(this.$t('Failed to save'), 'error')
                                console.log(error);
                                //console.log(error.response.status);
                                //alert(error.response.status)
                            })
                    } else {
                        store.dispatch('app-dealer/updateDealer', {
                                dealerData: this.dealerData
                            })
                            .then(response => {
                                this.msgDialog(this.$t('Successful update'), 'success')
                                //this.clearForm();

                            })
                            .catch(error => {
                                this.msgDialog(this.$t('FAILURE to update'), 'error')

                                console.log(error.response.status)
                            })
                    }

                    //alert('form submitted!')
                }
            })
        },

        clearForm() {
            this.dealerData = {
                id: "",
                name: "",
                document: "",
                email: "",
                isCorporate: true,
                corporateName: "",
                tradeName: "",
                stateInscription: "",
                note: "",
                alertEmail: "",
                alertPhone: "",
                manager: "",
                managerEmail: "",
                managerPhone: "",
                techManager: "",
                techEmail: "",
                techPhone: "",
                financeManager: "",
                financeEmail: "",
                financePhone: "",
                address: "", // numero rua e complemento
                neighborhood: "", //bairro country
                zipCode: "",
                country: "",
                state: "",
                domainName: "",
                planId: ``,
                dealerTypeId: ``
            }
        },

        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 2,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },

        selectHandler(option) {
            this.selected = option.item
            this.filteredOptions = []
            this.searchQuery = option.item.name
            this.dealerData.customerId = option.item.id

            console.log(`option selecionado `, this.dealerData.customerId)
        },

        onInputChange(text) {
            if (text === '' || text === undefined || text.length < 3) {

                return
            }

            store.dispatch('app-dealer/fetchDealers', {
                    q: this.searchQuery,
                    page: 1,
                    perPage: 10

                })
                .then(response => {
                    console.log(`resposta auto `, response.data)

                    this.filteredOptions = [{
                        data: response.data.data,
                    }]

                })
                .catch(error => {

                    console.log(error)

                })
        },
        suggestionSelected() {
            console.log('Selecionado', this.selected)
        }
    },
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale)
        },
    },
    setup() {
        const dealerData = ref(null)
        const planOpt = ref([])
        const searchQuery = ref('')

        const DEALER_APP_STORE_MODULE_NAME = 'app-dealer'

        const locales = [{
                locale: 'en',
                img: require('@/assets/images/flags/en.png'),
                name: 'English',
            },
            {
                locale: 'pt',
                img: require('@/assets/images/flags/br.png'),
                name: 'Português',
            },
            {
                locale: 'es',
                img: require('@/assets/images/flags/ar.png'),
                name: 'Espanõl',
            },
        ]

      const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

        // Register module
        if (!store.hasModule(DEALER_APP_STORE_MODULE_NAME)) store.registerModule(DEALER_APP_STORE_MODULE_NAME, dealerStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(DEALER_APP_STORE_MODULE_NAME)) store.unregisterModule(DEALER_APP_STORE_MODULE_NAME)
        })

        if (router.currentRoute.params.id != undefined) {

            store.dispatch('app-dealer/fetchDealer', {
                    id: router.currentRoute.params.id
                    //id: "0871b37d-f9a7-4775-af82-f3f8e017a801"
                })
                .then(response => {

                    response.data.teste = "teste"
                    console.log("Testando ---", response.data)
                    dealerData.value = response.data;

                    if (response.data.ownerId) {

                        store.dispatch('app-dealer/fetchDealer', {
                                //id: router.currentRoute.params.id
                                id: response.data.ownerId
                            })
                            .then(res => {
                                searchQuery.value = res.data.name;
                                

                            })
                            .catch(error => {
                                console.log(`Error fetch owner id `)
                            })

                    }

                })
                .catch(error => {

                    //dealerData.value = undefined

                })
        } else {
            dealerData.value = {
                id: "",
                name: "",
                document: "",
                email: "",
                isCorporate: true,
                corporateName: "",
                tradeName: "",
                stateInscription: "",
                note: "",
                alertEmail: "",
                alertPhone: "",
                manager: "",
                managerEmail: "",
                managerPhone: "",
                techManager: "",
                techEmail: "",
                techPhone: "",
                financeManager: "",
                financeEmail: "",
                financePhone: "",
                address: "", // numero rua e complemento
                neighborhood: "", //bairro
                zipCode: "",
                country: "",
                state: "",
                domainName: "",
                planId: ``,
                customerTypeId: `4`,
                customerId: null,
                language: `pt`,
                timezone: `-3`
            }
        }

        //Fetch Plans
        store.dispatch('app-dealer/fetchPlans')
            .then(response => {
                response.data.data.map(function (v, key) {
                    const id = parseInt(v.id)
                    console.log(id)
                    planOpt.value.push({
                        label: v.name,
                        value: id
                    }, );
                });

            })
            .catch(error => {

            })


        return {
            dealerData,
            planOpt,
            searchQuery,
            locales,
            user
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style><style>
.title-head {
    margin-bottom: 32px;
}
</style>
