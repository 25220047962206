var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',[_vm._v(_vm._s(_vm.$t('Dealer')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('The fields with (*) are required')))]),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Owner'),"label-for":"Dealer"}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps},on:{"selected":_vm.selectHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.ownerName),expression:"suggestion.item.ownerName"}]},[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('Belongs to: ')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(suggestion.item.ownerName)+" ")])])]}}]),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('b-col',{staticStyle:{"list-style":"none"},attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Language'),"label-for":"language"}},[_c('validation-provider',{attrs:{"name":"profile","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.languageOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"profile"},model:{value:(_vm.dealerData.language),callback:function ($$v) {_vm.$set(_vm.dealerData, "language", $$v)},expression:"dealerData.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h4',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('Informations')))]),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Is Corporate'),"label-for":"is-corporate"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.isCorporateOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"is-corporate"},model:{value:(_vm.dealerData.isCorporate),callback:function ($$v) {_vm.$set(_vm.dealerData, "isCorporate", $$v)},expression:"dealerData.isCorporate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Plan'),"label-for":"plan"}},[_c('validation-provider',{attrs:{"name":"plan","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.planOpt,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"plan"},model:{value:(_vm.dealerData.planId),callback:function ($$v) {_vm.$set(_vm.dealerData, "planId", $$v)},expression:"dealerData.planId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name')+'*',"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.dealerData.name),callback:function ($$v) {_vm.$set(_vm.dealerData, "name", $$v)},expression:"dealerData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.dealerData.isCorporate == true),expression:"dealerData.isCorporate == true"}],attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Corporate Name'),"label-for":"corporate-name"}},[_c('validation-provider',{attrs:{"name":"corporate-name","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"corporate-name"},model:{value:(_vm.dealerData.corporateName),callback:function ($$v) {_vm.$set(_vm.dealerData, "corporateName", $$v)},expression:"dealerData.corporateName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.dealerData.isCorporate == true),expression:"dealerData.isCorporate == true"}],attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Trade Name'),"label-for":"trade-name"}},[_c('validation-provider',{attrs:{"name":"trade-name","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"trade-name"},model:{value:(_vm.dealerData.tradeName),callback:function ($$v) {_vm.$set(_vm.dealerData, "tradeName", $$v)},expression:"dealerData.tradeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.dealerData.isCorporate == true ? 'CNPJ*' : 'CPF*',"label-for":"document"}},[_c('validation-provider',{attrs:{"name":"document","rules":"required|min:11|max:20|regex:^[0-9-.\\/]*$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"document","raw":false,"options":_vm.dealerData.isCorporate == true ? _vm.options.cnpj : _vm.options.cpf},model:{value:(_vm.dealerData.document),callback:function ($$v) {_vm.$set(_vm.dealerData, "document", $$v)},expression:"dealerData.document"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('State Inscription'),"label-for":"state-inscription"}},[_c('validation-provider',{attrs:{"name":"state-inscription","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"state-inscription"},model:{value:(_vm.dealerData.stateInscription),callback:function ($$v) {_vm.$set(_vm.dealerData, "stateInscription", $$v)},expression:"dealerData.stateInscription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Timezone*","label-for":"time"}},[_c('validation-provider',{attrs:{"name":"time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"time"},model:{value:(_vm.dealerData.timezone),callback:function ($$v) {_vm.$set(_vm.dealerData, "timezone", $$v)},expression:"dealerData.timezone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h4',{staticClass:"mt-3"},[_c('h4',[_vm._v(_vm._s(_vm.$t('Contacts')))])]),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Manager'),"label-for":"manager"}},[_c('validation-provider',{attrs:{"name":"manager","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"manager"},model:{value:(_vm.dealerData.manager),callback:function ($$v) {_vm.$set(_vm.dealerData, "manager", $$v)},expression:"dealerData.manager"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Manager Email'),"label-for":"manager-email"}},[_c('validation-provider',{attrs:{"name":"manager-email","rules":"max:255|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"manager-email","type":"email"},model:{value:(_vm.dealerData.managerEmail),callback:function ($$v) {_vm.$set(_vm.dealerData, "managerEmail", $$v)},expression:"dealerData.managerEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Manager Phone'),"label-for":"manager-phone"}},[_c('validation-provider',{attrs:{"name":"manager-phone","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"manager-phone","raw":false,"options":_vm.phoneMask,"placeholder":"31 9 9999 0000"},model:{value:(_vm.dealerData.managerPhone),callback:function ($$v) {_vm.$set(_vm.dealerData, "managerPhone", $$v)},expression:"dealerData.managerPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Tech Manager'),"label-for":"tech-manager"}},[_c('validation-provider',{attrs:{"name":"tech-manager","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tech-manager"},model:{value:(_vm.dealerData.techManager),callback:function ($$v) {_vm.$set(_vm.dealerData, "techManager", $$v)},expression:"dealerData.techManager"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Tech Manager Email'),"label-for":"tech-email"}},[_c('validation-provider',{attrs:{"name":"tech-email","rules":"max:250|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tech-email","type":"email"},model:{value:(_vm.dealerData.techEmail),callback:function ($$v) {_vm.$set(_vm.dealerData, "techEmail", $$v)},expression:"dealerData.techEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Tech Manager Phone'),"label-for":"tech-phone"}},[_c('validation-provider',{attrs:{"name":"tech-phone","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"tech-phone","raw":false,"options":_vm.phoneMask,"placeholder":"31 9 9999 0000"},model:{value:(_vm.dealerData.techPhone),callback:function ($$v) {_vm.$set(_vm.dealerData, "techPhone", $$v)},expression:"dealerData.techPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Finance Manager'),"label-for":"finance-manager"}},[_c('validation-provider',{attrs:{"name":"finance","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"finance-manager"},model:{value:(_vm.dealerData.financeManager),callback:function ($$v) {_vm.$set(_vm.dealerData, "financeManager", $$v)},expression:"dealerData.financeManager"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Finance Manager Email'),"label-for":"finance-email"}},[_c('validation-provider',{attrs:{"name":"finance-email","rules":"max:250|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"finance-email","type":"email"},model:{value:(_vm.dealerData.financeEmail),callback:function ($$v) {_vm.$set(_vm.dealerData, "financeEmail", $$v)},expression:"dealerData.financeEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Finance Manager Phone'),"label-for":"tech-phone"}},[_c('validation-provider',{attrs:{"name":"finance-phone","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"finance-phone","raw":false,"options":_vm.phoneMask,"placeholder":"21 9 9999 0000"},model:{value:(_vm.dealerData.financePhone),callback:function ($$v) {_vm.$set(_vm.dealerData, "financePhone", $$v)},expression:"dealerData.financePhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Zip Code'),"label-for":"zipcode"}},[_c('validation-provider',{attrs:{"name":"zipcode","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"zipcode","raw":false,"options":_vm.zipMask},model:{value:(_vm.dealerData.zipCode),callback:function ($$v) {_vm.$set(_vm.dealerData, "zipCode", $$v)},expression:"dealerData.zipCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Country'),"label-for":"country"}},[_c('validation-provider',{attrs:{"name":"country","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country"},model:{value:(_vm.dealerData.country),callback:function ($$v) {_vm.$set(_vm.dealerData, "country", $$v)},expression:"dealerData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Neighborhood'),"label-for":"neighborhood"}},[_c('validation-provider',{attrs:{"name":"neighborhood","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"neighborhood"},model:{value:(_vm.dealerData.neighborhood),callback:function ($$v) {_vm.$set(_vm.dealerData, "neighborhood", $$v)},expression:"dealerData.neighborhood"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Address'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","placeholder":"Rua Oito, 123 - Casa C"},model:{value:(_vm.dealerData.address),callback:function ($$v) {_vm.$set(_vm.dealerData, "address", $$v)},expression:"dealerData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Notes'),"label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"address","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"notes","rows":"5"},model:{value:(_vm.dealerData.note),callback:function ($$v) {_vm.$set(_vm.dealerData, "note", $$v)},expression:"dealerData.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.user.role.endsWith('VI'))?_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }